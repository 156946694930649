<template>
  <base-view title="Historial de Pagos" icon="archive">
    <div>
      <check-authorization :requiresAuthorizations="['historial pagos propiedades']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Historial de Pagos
          </b-card-header>

          <b-card-body>
            <x-select
              v-model="propiedad"
              dispatchPath="departamentoModule/getResource"
              getterListPath="departamentoModule/getResourceList"
              :formatListWith="['id', 'num_catastral']"
              defaultText="-- Selecciona una propiedad --"
              noClearButton
              prependText="Propiedad"
            >
            </x-select>

            <historial-pagos-list
              class="mt-4"
              v-if="propiedad"
              :propiedad="propiedad"
            >
            </historial-pagos-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import HistorialPagosList from '@/components/Operaciones/HistorialPagos/HistorialPagosList'

export default {
  name: 'HistorialPagosMainView',

  components: {
    HistorialPagosList
  },

  data () {
    return {
      hideButton: false,
      showForm: false,
      propiedad: null
    }
  }
}
</script>

<style scoped>

</style>
