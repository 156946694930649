<template>
  <div>
    <!-- Formulario -->
    <portal-target name="notificationSuficienciaSidebarAlert" />

    <loading v-if="loading"></loading>
    <b-form v-else @submit.prevent="submit">
      <!-- Archivo layout -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Archivo layout
            <span class="text-danger">*</span>
          </label>
        </template>

        <b-form-file
          size="sm"
          v-model="payload.file"
          :state="Boolean(payload.file)"
          placeholder="Selecciona el archivo layout excel...">
          <template slot="file-name" slot-scope="{ names }">
            <b-badge variant="dark">{{ names[0] }}</b-badge>
          </template>
        </b-form-file>
      </b-form-group>

      <div class="my-2 d-flex">
        <slot name="btn-cancel-place" v-bind="{ clearForm }"></slot>

        <b-button
          type="submit"
          variant="success"
          size="sm"
          class="ml-auto"
          :disabled="!isFormValid"
        >
          Subir
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
const STORE_MODULE = 'rentaModule'

const payload = () => ({
  file: null,
  save_file: false,
  overwrite: false
})

const errors = () => ({
  rows: []
})

export default {
  name: 'RentaFormImport',

  props: {
    periodo_id: {
      required: true
    }
  },

  data () {
    return {
      payload: payload(),

      errors: errors(),

      loading: false
    }
  },

  computed: {
    isFormValid () {
      return [Boolean(this.payload.file)].every(e => e)
    }
  },

  methods: {
    async submit () {
      this.errors = errors()

      this.loading = true
      const getval = await this.$store.dispatch(`${STORE_MODULE}/import`, {
        periodo_id: this.periodo_id,
        file: this.payload.file
      })
      this.loading = false

      //
      // Borra el formulario, exista error o no
      this.clearForm()

      if (getval.error) {
        //
        // Obtiene los errores, si es que los hay
        this.errors.rows = getval.data

        this.$alert(
          getval,
          'Importación de layout para actualización de programación de pagos.',
          'notificationSuficienciaSidebarAlert',
          { status: 'danger' }
        )
      }

      //
      // Muestra mensaje de éxito y emite que ha terminado el proceso
      this.showMessage(getval.message)
      this.emitResult(getval)
    },

    clearForm () {
      this.payload = payload()
      this.errors = errors()
      this.$clearAlert()
    }
  }
}
</script>

<style scoped>

</style>
