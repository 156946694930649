<template>
  <div>
    <!-- Table -->
    <b-card no-body>
      <b-card-header>
        <div class="w-100 d-flex justify-content-between">
          <b-button
            class="btn-sm btn-outline-success mr-3 mb-2 mb-sm-0"
            size="sm"
            variant="white"
            :disabled="!getAvailableList || getResourceData.length <= 0" @click="onExport">
            Exportar
          </b-button>
          <b-button
            class="btn-sm btn-outline-primary mr-3 mb-2 mb-sm-0"
            size="sm"
            variant="white"
            @click="showModal = true">
            Importar
          </b-button>
        </div>
      </b-card-header>

      <b-table
        id="rentasTable"
        v-if="getAvailableList"
        :fields="fields"
        :items="getResourceListData"
        striped
        bordered
        responsive
        hover
        stacked="lg"
        show-empty
        small
      >
        <!-- Eliminado -->
        <template #cell(is_deleted)="row">
          <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
          <b-badge v-else variant="light">Sí</b-badge>
        </template>

        <template #cell(periodo)="row">
          <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.periodo }}
          </span>
        </template>

        <template #cell(importe_pagado)="row">
          <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.importe_pagado }}
          </span>
        </template>

        <template #cell(fecha_pago)="row">
          <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.fecha_pago }}
          </span>
        </template>

        <!-- Detalle -->
        <template #cell(show_details)="row">
          <b-button
            @click="row.toggleDetails"
            variant="light"
            class="btn-sm mb-2 btn-block btn-outline-black"
          >
            Detalles
          </b-button>
        </template>

        <!-- Acciones -->
        <template #row-details="row">
          <b-card
            class="mb-1"
            border-variant="primary"
            header-bg-variant="primary"
            header-text-variant="white"
            header="Detalle"
            no-body
          >
            <b-card-body>
              <b-row class="mb-2">
                <b-col cols="12" md="6">
                  <b-card
                    header="Datos de la renta"
                    no-body
                    border-variant="primary"
                    class="mb-4"
                    style="height: 500px; overflow: auto;"
                  >
                    <b-list-group flush>
                      <b-list-group-item>
                        <b>Importe:</b>
                        {{ row.item.importe }}
                      </b-list-group-item>

                      <b-list-group-item>
                        <b>Total de traslados:</b>
                        {{ row.item.total_traslados }}
                      </b-list-group-item>

                      <b-list-group-item>
                        <b>Total de retenciones:</b>
                        {{ row.item.total_retenciones }}
                      </b-list-group-item>

                      <b-list-group-item>
                        <b>A pagar:</b>
                        {{ row.item.a_pagar }}
                      </b-list-group-item>

                      <b-list-group-item>
                        <b>Estado:</b>
                        {{ row.item.status }}
                      </b-list-group-item>

                      <b-list-group-item>
                        <b>Período de pago:</b>
                        {{ row.item.periodo_pago.descriptor }}
                      </b-list-group-item>

                      <b-list-group-item>
                        <b>Período de captura:</b>
                        {{ row.item.periodo_captura.descriptor }}
                      </b-list-group-item>

                      <b-list-group-item>
                        <b>Departamento:</b>
                        {{ row.item.departamento.cuenta_predial }}
                      </b-list-group-item>

                      <b-list-group-item>
                        <b @click="toggleCFDI" style="cursor: pointer;">CFDI:</b>
                        <div v-if="showCFDI">
                          <ul>
                            <li><b>Cantidad:</b> {{ row.item.cfdi_concepto.cantidad }}</li>
                            <li><b>Producto/Servicio:</b> {{ row.item.cfdi_concepto.clave_prod_serv }}</li>
                            <li><b>Unidad:</b> {{ row.item.cfdi_concepto.clave_unidad }}</li>
                            <li><b>Descripción:</b> {{ row.item.cfdi_concepto.descripcion }}</li>
                          </ul>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </b-card>
                </b-col>
              </b-row>

              <b-card no-body>
                <b-button
                  variant="white"
                  size="sm"
                  class="btn-outline-black"
                  @click="row.toggleDetails"
                >
                  Cerrar detalle
                </b-button>
              </b-card>
            </b-card-body>
          </b-card>

          <!-- El botón de "ver como", únicamente lo puede usar el usuario con rol "admin" -->
        </template>
      </b-table>
      <loading v-else></loading>
    </b-card>

    <b-modal
      id="importarActualizacionPagosModal"
      v-model="showModal"
      centered
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
    >
      <template #modal-title>Importar actualización de pagos</template>
      <template #default="{cancel}">
        <renta-form-import :periodo_id="periodo" @on-sent="cancel()">
          <template #btn-cancel-place="{ clearForm }">
            <b-button
              variant="black"
              size="sm"
              @click="
                cancel()
                clearForm()
              "
            >
              Cancelar
            </b-button>
          </template>
        </renta-form-import>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RentaFormImport from '../Rentas/RentaFormImport.vue'

const STORE_MODULE = 'historialPagosModule'

const response = () => ({
  error: false,
  message: '',
  errors: {}
})

export default {
  name: 'HistorialPagosList',

  components: {
    RentaFormImport
  },

  created () {
    this.submit()
  },

  props: {
    propiedad: null
  },

  data () {
    return {
      showModal: false,

      selectedId: null,

      isLoading: false,

      response: response(),

      showCFDI: false,

      fields: [
        { key: 'periodo', label: 'Período' },
        { key: 'importe_pagado', label: 'Importe' },
        { key: 'fecha_pago', label: 'Fecha de Pago' }
      ]
    }
  },

  watch: {
    propiedad: {
      immediate: true,
      handler (value) {
        this.submit()
      }
    }
  },

  computed: {
    ...mapGetters(STORE_MODULE, [
      'getResourceData',
      'getResourceListData',
      'getAvailableList',
      'isThereData',
      'getResource'
    ]),
    ...mapGetters('departamentoModule', ['getResourceList'])
  },

  methods: {
    async submit () {
      const { error, message } = await this.$store.dispatch(`${STORE_MODULE}/getResource`, [this.propiedad])

      console.log(this.getResource)

      if (error) this.$notify({ error, message }, 'Rentas')
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    },

    async onExport () {
      this.loading = true
      const getval = await this.$store.dispatch(`${STORE_MODULE}/export`, [this.periodo])
      this.loading = false

      // Obtenemos periodos
      const periodos = this.getResourceList
      const periodo = periodos.find(p => p.id === this.periodo)

      await this.$onDownload(getval, `pagos_programados_${periodo.descriptor}.xlsx`)

      this.$notify({ message: 'Solicitud de exportación de programación de pagos' })
    },

    toggleCFDI () {
      this.showCFDI = !this.showCFDI
    }
  }
}
</script>

<style scoped>

</style>
